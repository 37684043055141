import * as React from "react";
import {IonIcon} from "@ionic/react";

import styles from "./GeoFaWidgetBadge.module.css";
import {downloadOutline} from "ionicons/icons";

interface GeoFaWidgetBadgeProps {
    children?: React.ReactNode;
    icon: any,
}

const GeoFaWidgetBadge: React.FC<GeoFaWidgetBadgeProps> = ({
                                                               icon,
                                                               children,
                                                           }) => {

    return (
        <div className={styles.badge}>
            <div className={styles.icon}>
                <IonIcon icon={icon} className={styles.downloadIcon}></IonIcon>
            </div>
            <div className={styles.label}>{children}</div>
        </div>
    )

};


export default GeoFaWidgetBadge;
