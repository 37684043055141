// TODO: move this into a central config file

// import {cats} from "../utils/geopark_setup";
import {cats} from "../utils/toender_setup";


import {AppConfig} from "../utils/AppConfig";
import {GeoJSON} from "ol/format";
import {Geometry} from "ol/geom";
import {Feature} from "ol";
import {CommonFilterItem} from "../contexts/MapContextProvider";
import {IThemegroup} from "../interfaces/ICategoryItem";
import {EGeoType, ESource} from "./enumarations";

const ENDPOINT_URL = AppConfig.apiBaseUrl

// Enum format, geoformat
interface IGeoFAQuery {
    q: string
    format?: string
    geoformat?: string
}

class GeoFAService {

    constructor() {}

    PHOTO_URL = AppConfig.photoBaseUrl;

    // THEME_POINTS = "fkg.t_5800_fac_pkt";
    THEME_ROUTES = "fkg.t_5802_fac_li";
    THEME_POLYGONS = "fkg.t_5801_fac_fl";

    POINT_RETURN_FIELDS = [
        "objekt_id",
        "navn",
        "facil_ty",
        "facil_ty_k",
        "kommunekode",
        "geometri",
        "beskrivels", // TODO: move to DetailPage.tsx
        "lang_beskr", // TODO: move to DetailPage.tsx
        "foto_link",
        "geofafoto",
        "temakode"
    ]

    // The minimum set of values we need for all locations apart from the Detail View.
    MIN_FIELDS_5800 = [
        "geometri",
        "objekt_id",
        "facil_ty_k",
        "facil_ty",
        "temakode",
        "navn",
        "navn_d",
        "navn_uk",
        "off_kode"
    ]

    MIN_FIELDS_5802 = [
        "geometri",
        "objekt_id",
        "rute_ty_k",
        "beskrivels",
        "temakode",
        "navn",
        "off_kode",
        "gpx_link",
        "rute_uty_k"
    ]

    //
    MIN_FIELDS_5801 = [
        "geometri",
        "objekt_id",
        "facil_ty_k",
        "facil_ty",
        "beskrivels",
        "temakode",
        "navn",
        "off_kode"
    ]

    // Type guard function
    // TODO: put into helpers.ts 2025
    isCommonFilterItem(obj: any): obj is CommonFilterItem {
        return (
            obj &&
            typeof obj === 'object' &&
            typeof obj.category === 'number' &&
            Object.values(ESource).includes(obj.source)
        );
    }

    // Helper type guard to check if the object has a `themes` property
    // TODO: put into helpers.ts 2025
    isThemeGroup(item: CommonFilterItem | IThemegroup): item is IThemegroup {
        return typeof item === 'object' && item !== null && 'themes' in item;
    }

    /*
    Get the facility types for points from the filter setup in <TODO>
     */
    getFacilityPointTypes(): CommonFilterItem[] {
        return cats
            .filter(cat => cat.geotype === EGeoType.Point) // Filter categories with geotype Point
            .flatMap(cat => cat.themes) // Extract themes from filtered categories
            .flatMap(theme => {
                // Check if the theme has a nested `themes` property
                if (this.isThemeGroup(theme)) {
                    return theme.themes; // Access themes safely
                }
                // Validate and include common filter items
                return this.isCommonFilterItem(theme) ? [theme] : [];
            });
    }

    // TODO: 2025
    getIN(keys: number[] | CommonFilterItem[], key: keyof CommonFilterItem = 'category'): string {
        if (typeof keys[0] === 'number') {
            // If keys is an array of numbers
            return `(${keys.join(', ')})`;
        } else if (typeof keys[0] === 'object') {
            // If keys is an array of CommonFilterItem objects
            const keyValues = (keys as CommonFilterItem[]).map(item => item[key]);
            return `(${keyValues.join(', ')})`;
        } else {
            // Invalid case
            throw new Error('Invalid input: keys must be an array of numbers or CommonFilterItem objects.');
        }
    }
        /*
        console.log('keys', keys);
        if (Array.isArray(keys)) {
            // If keys is an array of numbers, join them into a string
            const keyValues = keys.map(item => item.category);
            return `(${keyValues.join(', ')})`;
        } else {
            // If keys is a CommonFilterItem, extract meaningful values
            // const { category, source, type } = keys;
            // return `(${category}, ${source}, ${type})`;
            return 'xxxx';
        }
         */


    // TODO: WHERE_CLAUSE_LINES
    getWhereClauseLines(municipalCode: number) {
        return `%20WHERE%20kommunekode=' + this.knr() + '%20OR%20beliggenhedskommune= ${municipalCode}`;
    }

    getWhereClausePolygons(municipalCode: number) {
       return `%20WHERE%20kommunekode=${municipalCode}%20OR%20beliggenhedskommune=${municipalCode}`;
    }

    getPointQuery(municipalCode: number) {
        return  {
            q: "SELECT%20" + 'foo',
            format: 'geojson',
            geoformat: 'geojson'
        }
    }

    getLineQuery(municipalityCode: number) {
        return {
            q: "SELECT%20" + this.MIN_FIELDS_5802.join(',') + '%20FROM%20' +
                this.THEME_ROUTES + this.getWhereClauseLines(municipalityCode),
            format: 'geojson',
            geoformat: 'geojson'
        }
    }

    getPolygonQuery(municipalityCode: number) {
        return {
            q: "SELECT%20" + this.MIN_FIELDS_5801.join(',') + '%20FROM%20' +
                this.THEME_POLYGONS + this.getPolygonsQueryString(municipalityCode),
            format: 'geojson',
            geoformat: 'geojson'
        }
    }

    public dictToURI(dict: object) {
        let s = "";
        Object.entries(dict).forEach(
            ([key, value]) => {
                s = String(s) + "&" + key + "=" + value;
            }
        );
        return s.substring(1);
    }

    public getPointsQueryString(municipalityCode: number): string {
        return this.dictToURI(this.getPointQuery(municipalityCode));
    }

    public getLinesQueryString(municipalityCode: number): string {
        return this.dictToURI(this.getLineQuery(municipalityCode));
    }

    public getPolygonsQueryString(municipalityCode: number): string {
        return this.dictToURI(this.getPolygonQuery(municipalityCode));
    }

    public getPointsURL(municipalityCode: number): string {
        return ENDPOINT_URL + '?' + this.getPointsQueryString(municipalityCode);
    }


    public getLinesURL(municipalityCode: number): string {
        return ENDPOINT_URL + '?' + this.getLinesQueryString(municipalityCode);
    }

    public getPolygonsURL(municipalityCode: number): string {
        return ENDPOINT_URL + '?' + this.getLinesQueryString(municipalityCode);
    }

    public async getFeatures<TResponse>(municipalityCode: number, type?: EGeoType, fields?: string[]): Promise<TResponse> {

        // Fallback to minimal
        let flds = fields || this.MIN_FIELDS_5800;

        let sql: string= '';

        if (type === EGeoType.Point) {
            if (this.getFacilityPointTypes() && this.getFacilityPointTypes().length > 0) {
                sql = `
                    SELECT ${flds.join(',')} 
                    FROM fkg.${type} 
                    WHERE beliggenhedskommune=${municipalityCode} 
                    AND facil_ty_k IN ${this.getIN(this.getFacilityPointTypes())}`;
            } else {
                sql = `SELECT ${flds.join(',')} FROM fkg.${type} WHERE beliggenhedskommune=${municipalityCode}`;
            }
        }

        if (type === EGeoType.Line) {
            if (AppConfig.FACILITY_LINES && AppConfig.FACILITY_LINES.length > 0) {
                sql = `
                    SELECT ${flds.join(',')} 
                    FROM fkg.${type} 
                    WHERE beliggenhedskommune=${municipalityCode} 
                    AND rute_ty_k IN ${this.getIN(AppConfig.FACILITY_LINES)}`;
            } else {
                sql = `SELECT ${flds.join(',')} FROM fkg.${type} WHERE beliggenhedskommune=${municipalityCode}`;
            }
        }

        if (type === EGeoType.Line) {
            if (AppConfig.FACILITY_POLYGONS && AppConfig.FACILITY_POLYGONS.length > 0) {
                sql = `SELECT ${flds.join(',')} FROM fkg.${type} WHERE beliggenhedskommune=${municipalityCode} AND facil_ty_k IN ${this.getIN(AppConfig.FACILITY_POLYGONS)}`;
            } else {
                sql = `SELECT ${flds.join(',')} FROM fkg.${type} WHERE beliggenhedskommune=${municipalityCode}`;
            }
        }

        const QUERY= {
            q: encodeURI(sql),
            format: 'geojson',
            geoformat: 'geojson',
            srs: AppConfig.srs
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => {

                // Doing this by a query did not work, so we filter old school
                const rawFeatures: Feature<Geometry>[] = new GeoJSON().readFeatures(data);
                const ftrs: Feature<Geometry>[] = []

                rawFeatures.forEach((f) => {
                    if (f.getProperties().off_kode == 1) {

                        // console.log(f.getProperties());

                        // We differentiate the "undertype" for the Natureventyr
                        // TODO: Put this into a service
                        if ( f.getProperties().rute_ty_k == 5 && f.getProperties().rute_uty_k == 17)  {
                            const nProp = f.getProperties();
                            nProp.rute_ty_k = 501;
                            f.setProperties(nProp);
                            // console.log(`Rute ${f.getProperties().navn} changed to be a Natureventyr (${f.getProperties().rute_ty_k}).`)
                        }
                        ftrs.push(f);
                    }
                });

                return ftrs as TResponse;
            })
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }

    /**
     * Return a set of electrical charging station data from geoFA
     * @param municpalCode
     */
    public async getElladestandere<TResponse>(municpalCode: number):Promise<TResponse>  {

        const sql = `SELECT * FROM fkg.t_5607_ladefacilitet WHERE ladefacilitet_type_kode=2 AND kommunekode=${municpalCode}`;

        const QUERY = {
            q: encodeURI(sql),
            format: 'geojson',
            geoformat: 'geojson',
            srs: AppConfig.srs
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => {
                const ftrs = new GeoJSON().readFeatures(data);
                console.log(`${ftrs.length} Ladere fundet i kommune ${municpalCode}`);
                return  ftrs as TResponse;
            }).catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }

    /*
    Get all features within the given (now hardcoded) bbox and of a certain municipalCodes.
    Right now we use it for 999 - Naturstyrelsen, which has facilities all over DK.
     */
    public async getFeaturesWithinBBOX<TResponse>(kommunenr?: number, fields?: string[]):Promise<TResponse>  {

        const flds = fields || this.MIN_FIELDS_5800;
        const kmk = kommunenr ? `kommunekode=${kommunenr} OR beliggenhedskommune=${kommunenr}` : '';

        // TODO: Unhardcode bbox #34 and put it into settings
        const sql = `SELECT ${flds.join(',')}
            FROM fkg.t_5800_fac_pkt
            WHERE ST_Intersects(
                geometri,
                ST_Transform(ST_SetSRID(ST_GeomFromGeoJSON('{
                    "type": "Polygon",
                    "coordinates": [
                        [
                            [
              8.466627413933411,
              54.88033826612854
            ],
            [
              9.246846976576624,
              54.88125831765106
            ],
            [
              9.249092415514076,
              55.244496853818035
            ],
            [
              8.46177582338953,
              55.243564368513695
            ],
            [
              8.466627413933411,
              54.88033826612854
            ]
                        ]
                    ]
                }'), 4326), 25832)
            )
            ${kmk}`;

        const QUERY= {
            q: encodeURI(sql),
            format: 'geojson',
            geoformat: 'geojson',
            srs: AppConfig.srs
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => {
                const ftrs = new GeoJSON().readFeatures(data);
                //this.guidLookup.addFeatures(ftrs);
                return  ftrs as TResponse;
            })
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }

    /**
     * Return a feature from geoFA
     * @param objectID - an objects objekt_id
     * @param type  - the type in fkg
     */
    public async getSingleFeatureFromgeoFA<TResponse>(objectID: string, type?: EGeoType): Promise<TResponse> {

        // const table = 'fkg';
        const table = 'fkg_foto_gpx_link';

        const sql = `SELECT * FROM ${table}.${type} WHERE objekt_id='${objectID}'`;
        // TODO: add geotype lookup
        const QUERY: IGeoFAQuery = {
            q: encodeURI(sql),
            format: 'geojson',
            geoformat: 'geojson'
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        const resp = await fetch(queryString);
        const data = await resp.json();

        return data as TResponse;

    }

    public async getFeaturesForUIDList<TResponse> (ll: string[], type?: EGeoType, fields?: string[]): Promise<TResponse> {


        const quotedList = ll.map(item => `'${item}'`);
        const concatenatedString = quotedList.join(',');
        const sql= `SELECT * FROM fkg_foto_gpx_link.t_5802_fac_li WHERE objekt_id IN (${concatenatedString})`;

        const QUERY: IGeoFAQuery = {
            q: encodeURI(sql),
            format: 'geojson',
            geoformat: 'geojson'
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => {
                return new GeoJSON().readFeatures(data) as TResponse;
            })
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });

    }

    /**
     *
     * @param bbox The bounding box.
     * @param type The geotype
     * @param fields fields
     */
    public async getFeaturesForBBOX<TResponse>(bbox: number[], type?: EGeoType, fields?: string[]): Promise<TResponse> {

        const __table = 't_5800_fac_pkt';

        let flds = fields || ['navn', 'facil_ty', 'kommunekode', 'cvr_kode', 'cvr_navn', 'beskrivels', 'geometri'];

        let QUERY: IGeoFAQuery = {
            q: encodeURI(`${AppConfig.apiBaseUrl}?q=SELECT ${flds.join(',')} FROM fkg.${__table} WHERE ST_Intersects(geometri, ST_MakeEnvelope(${bbox[0]}, ${bbox[1]}, ${bbox[2]}, ${bbox[3]}, 25832));`),
            format: 'geojson,',
            geoformat: 'geojson'
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY);

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => data as TResponse)
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }

    /*
    Let's get all photo connections to our object.
     */
    public async getPhotosForFeature<TResponse>(objekt_id: string): Promise<TResponse> {

        const sql = `SELECT * FROM t_7900_fotoforbindelse WHERE foto_objek='${objekt_id}'`;

        let QUERY: IGeoFAQuery = {
            q: encodeURI(sql)
        }

        let queryString = `${ENDPOINT_URL}?${this.dictToURI(QUERY)}`;

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => {
                return new GeoJSON().readFeatures(data) as TResponse
            })
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }

    /*
    This rather exotic call returns a list of objekt_id's where photos are attached to.
    We need this for the height our modal opens upwards -- so we do not expose the headline.
    Contains duplicates
     */
    public async getFeatureIDsWithPhotosAttached<TResponse>(): Promise<TResponse> {

        const sql = `SELECT foto_objek FROM t_7900_fotoforbindelse WHERE `+
            `kommunekode=${AppConfig.municipalCodes} OR beliggenhedskommune=${AppConfig.municipalCodes};`;

        const QUERY: IGeoFAQuery = {
            q: encodeURI(sql)
        }

        let queryString = ENDPOINT_URL + '?' + this.dictToURI(QUERY)

        return fetch(queryString)
            .then((resp) => resp.json())
            .then((data) => data as TResponse)
            .catch(e => {
                return Promise.reject({
                    status: e.status,
                    ok: false,
                    message: e.json,
                    body: e.json
                });
            });
    }
}

export default GeoFAService;
