import {createContext} from "react";
import * as ol from "ol";

interface ILocationMapContext {
    getFacilityMapState(): any
    mapObject: ol.Map
}

const defaultLocationMapData: ILocationMapContext = {
    getFacilityMapState(): any {},
    mapObject: new ol.Map({})
}

const LocationMapContext = createContext<ILocationMapContext>(defaultLocationMapData);

export default LocationMapContext;
