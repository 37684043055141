import {cats} from "./toender_setup";
// import {cats} from "./geopark_setup";

import {ICategoryItem} from "../interfaces/ICategoryItem";
import {IModalSetting} from "./setupInterfaces";
import {EFilterName} from "../services/enumarations";

interface IAppConfig {

    title: string

    // The base url of the app, without /
    appBaseUrl: string
    languageOn: boolean
    srs: number

    // The list of the Municipality Codes we want to pull the geoFA data for
    municipalCodes: number[]
    filterName: EFilterName
    off_kode: number // Publication name

    // A facility can be shared from the modal
    shareFacility: boolean;

    // Only points of these types will be retrieved from the GeoFA service
    FACILITY_LINES: number[];
    FACILITY_POLYGONS: number[];

    // Use the bbox method to retrieve data
    bbox: number[][] | boolean
    apiBaseUrl: string
    photoBaseUrl: string
    visitDenmark: boolean
    visitDenmarkWFSRequest?: string
    map: {
        zoomToExtent?: boolean,
        initialMapCenter: number[],
        initialMapZoom: number,
        hitTolerance: number,
        enableRotation: boolean
    }
    categories: ICategoryItem[]
    modals: {
        filter: IModalSetting,
        list: IModalSetting,
        mapClick: IModalSetting
    },
    swipers: {
        detail: {
            slidesPerView: number
        },
        photos: {
            slidesPerView: number
        }
    },
    distanceTappedLocation: number          // The distance between the current location and the one we have
                                            // tapped the orientation FAB last
}

export const AppConfig: IAppConfig = {

    title: 'Tønder Kommunes Friluftskort',
    appBaseUrl: 'http://localhost:3000',
    // appBaseUrl: 'https://friluftskort.nordiq-gis.dk',
    languageOn: true,
    municipalCodes: [550],
    filterName: EFilterName.beliggenhedskommune,
    off_kode: 1,
    shareFacility: false,
    FACILITY_LINES: [],
    FACILITY_POLYGONS: [],
    srs: 3857,
    bbox: false,
    apiBaseUrl: "https://geofa.geodanmark.dk/api/v2/sql/fkg",
    photoBaseUrl: "https://mapcentia-www.s3-eu-west-1.amazonaws.com/fkg/",
    visitDenmark: false,
    visitDenmarkWFSRequest: undefined, // TODO: rename to visitDenmarkWFSRequestUrl
    map: {
        zoomToExtent: false,
        initialMapCenter: [8.863673188688109, 54.939638767894486],
        initialMapZoom: 12,
        hitTolerance: 10,
        enableRotation: false
    },
    categories: cats,
    modals: {
        filter: {
            initialBreakPoint: 0.7,
            breakpoints: [0, 0.7, 1]
        },
        list: {
            initialBreakPoint: 0.4,
            breakpoints: [0, 0.4, 1]
        },
        mapClick: {
            initialBreakPoint: 0.4,
            breakpoints: [0, 0.4, 1]
        },
    },
    swipers: {
        detail: {
            slidesPerView: 1.1
        },
        photos: {
            slidesPerView: 1.1
        }
    },
    distanceTappedLocation: 100

}
