import * as React from "react";
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCol,
    IonContent,
    IonGrid,
    IonIcon,
    IonPage,
    IonRow
} from "@ionic/react";

import {LocationDetails, Locations, LOCATIONS} from "../../utils/locations";
import LocationMap from "./LocationMap/LocationMap";

import styles from "./LocationsPage.module.css";
import {arrowForwardOutline, downloadOutline, linkOutline} from "ionicons/icons";
import {Link} from "react-router-dom";
import {AppConfig} from "../../utils/AppConfig";

const LocationsPage: React.FC = () => {

    function getLocations() {

        let l = [];

        function stringifyDict(d:LocationDetails): string {
            return JSON.stringify(d, null, 2); // Pretty-print JSON
        }

        const sortedLocations = Object.keys(LOCATIONS)
            .sort()
            .reduce((obj, key) => {
                obj[key] = LOCATIONS[key];
                return obj;
            }, {} as Locations);

        for (const key in sortedLocations) {
            let location = LOCATIONS[key];
            l.push(
                <IonCard key={key}>
                    <IonCardHeader>
                        <Link to={{pathname: getLink(key)}} target="_blank">
                            <h2>
                                {key}
                                <IonIcon icon={arrowForwardOutline} className={styles.rotate}>
                                </IonIcon></h2>
                        </Link>
                    </IonCardHeader>
                    <IonCardContent>
                            <LocationMap locationName={key} location={location}></LocationMap>
                            <pre>{stringifyDict(location)}</pre>
                    </IonCardContent>
                </IonCard>
            );
        }
        return l;
    }

    function getLink(key: string) {
        let baseUrl = AppConfig.appBaseUrl
        return `${baseUrl}/location/${key}`;
    }

    return (
        <IonPage>
            <IonContent>
                <IonGrid>
                    <h1>Steder</h1>
                    <IonRow>
                        {getLocations()}
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default LocationsPage;
