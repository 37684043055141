import {ICategoryItem, IThemegroup} from "../../../interfaces/ICategoryItem";
import {useContext, useEffect, useState} from "react";
import MapContext from "../../../contexts/MapContext";

import styles from "./FilterCategoryIndicators.module.css";
import flattenArray from "../flatten";
import {CommonFilterItem} from "../../../contexts/MapContextProvider";
import {ESource} from "../../../services/enumarations";

interface FilterCategoryIndicatorsProps {
    category: ICategoryItem
}

/**
 * FilterCategoryIndicators is a line of dots that indicate selected / not selected filters on the top of the
 * Filter Harmonium.
 *
 * @param category
 * @constructor
 */
const FilterCategoryIndicators: React.FC<FilterCategoryIndicatorsProps> = ({category}) => {

    const {getState} = useContext(MapContext);

    const [result, setResult] = useState<boolean[]>([]);

    // Type guard function
    function isCommonFilterItem(obj: any): obj is CommonFilterItem {
        return (
            obj &&
            typeof obj === 'object' &&
            typeof obj.category === 'number' &&
            Object.values(ESource).includes(obj.source)
        );
    }

    /*
    Change the appearance of the dots according to filter state.
     */
    useEffect(() => {

        // A List of group IDs
        let groupIDs: number[] = [];

        console.log('category.themes: ', category.themes);

        let themes =  flattenArray(category.themes);

        console.log('themes: ', themes);

        let nt = category.themes.map((r: CommonFilterItem | IThemegroup) => {
            if (isCommonFilterItem(r)) {
                console.log('iscfi', r)
                return r
            } else {
                console.log('nisci ', r)
                if (r.number) {
                    groupIDs.push(r.number)
                }
                return r.themes
            }
        })

        const themeFilter = getState().themeFilter;

        // let flattenedThemeFilter: number[] = [];

        console.log('themes', themes);

        let x: boolean[] = [];
        themes.forEach(t => {
            console.log('t:', t);
            if (flattenArray(themeFilter).indexOf(t) > -1) {
                x.push(true)
            } else {
                x.push(false)
            }
        })

        console.log('x', x);

        if (themes.length === 0) {
            setResult(new Array(themes.length).fill(true));
        } else {
            setResult(x);
        }

    }, [getState, getState().themeFilter]);

    function getChecked(c: boolean) {
        return c ? styles.checked : styles.notChecked
    }

    function getDots() {
        console.log('dots: ', result)
        return result.map((bool, index) => (
            <div className={`${styles.indicator} ${getChecked(bool)}`}
                 key={`${category.label}-${bool}-${index}`}></div>
        ));
    }

    return (
        <div className={styles.indicators}>{getDots()}</div>
    );

}

export default FilterCategoryIndicators;
