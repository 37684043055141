import {CommonFilterItem} from "../../contexts/MapContextProvider";
import {ESource} from "../../services/enumarations";

// Type guard function
function isCommonFilterItem(obj: any): obj is CommonFilterItem {
    return (
        obj &&
        typeof obj === 'object' &&
        typeof obj.category === 'number' &&
        Object.values(ESource).includes(obj.source)
    );
}

function flattenArray(arr: any[]): any[] {

    let result: any[] = [];

    if (Array.isArray(arr)) {
        arr.forEach((item) => {

            if (!isCommonFilterItem(item)) {
                result = result.concat(item.themes);
            } else {
                result.push(item);
            }
        });
    } else {
        console.error('Input is not an array');
    }

    return result;
}

export default flattenArray;
