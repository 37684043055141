import {ICategoryItem} from "../interfaces/ICategoryItem";
import {EGeoType, ESource} from "../services/enumarations";

export const cats: ICategoryItem [] = [
    {
        label: 'Stier og ruter',
        label_de: 'Pfade und Routen',
        label_gb: 'Trails and routes',
        color: '#aaaaaa',
        geotype: EGeoType.Line,
        themes: [
            {category: 5, source: ESource.geofa, type: EGeoType.Line},
            {category: 501, source: ESource.geofa, type: EGeoType.Line},
            {category: 3, source: ESource.geofa, type: EGeoType.Line},
            {category: 4, source: ESource.geofa, type: EGeoType.Line},
            {category: 8, source: ESource.geofa, type: EGeoType.Line},
            {category: 9, source: ESource.geofa, type: EGeoType.Line}
        ]
    },
    {
        label: 'Vandaktiviteter',
        label_de: 'Aktivitäten am Wasser',
        label_gb: 'Water activities',
        color: '#aaaaaa',
        geotype: EGeoType.Point,
        themes: [
            {category: 1061, source: ESource.geofa, type: EGeoType.Point},
            {category: 6052, source: ESource.geofa, type: EGeoType.Point},
            {category: 6062, source: ESource.geofa, type: EGeoType.Point},
            {category: 4191, source: ESource.geofa, type: EGeoType.Point},
            {category: 4411, source: ESource.geofa, type: EGeoType.Point}
        ]
    },
    {
        label: 'Friluftsaktiviteter',
        label_de: 'Outdooraktivitäten',
        label_gb: 'Outdoor activties',
        color: '#b20d0d',
        geotype: EGeoType.Point,
        themes: [{
            number: 10000,
            label: 'Legepladser',
            label_de: 'Spielplätze',
            label_gb: 'Playgrounds',
            themes: [
                {category: 1031, source: ESource.geofa, type: EGeoType.Point},
                {category: 1041, source: ESource.geofa, type: EGeoType.Point}
            ],
            pictogram: undefined
        }, {category: 4111, source: ESource.geofa, type: EGeoType.Point},
            {category: 4222, source: ESource.geofa, type: EGeoType.Point},
            {category: 4291, source: ESource.geofa, type: EGeoType.Point},
            {category: 4101, source: ESource.geofa, type: EGeoType.Point},
            {category: 1191, source: ESource.geofa, type: EGeoType.Point}
        ]
    },
    {
        label: '(Frilufts)seværdigheder',
        label_de: '(Outdoor)sehenswürdigkeiten',
        label_gb: '(Outdoor)sights',
        color: '#aaaaaa',
        geotype: EGeoType.Point,
        themes: [
            {category: 5102, source: ESource.geofa, type: EGeoType.Point},
            {category: 2012, source: ESource.geofa, type: EGeoType.Point}, {
                label: 'Natur- og kulturformidling',
                label_de: 'Natur- und Kulturvermittlung',
                number: 10001,
                themes: [
                    {category: 1302, source: ESource.geofa, type: EGeoType.Point},
                    {category: 2092, source: ESource.geofa, type: EGeoType.Point},
                ],
                pictogram: undefined
            }, {category: 5072, source: ESource.geofa, type: EGeoType.Point},
            {category: 1152, source: ESource.geofa, type: EGeoType.Point}
        ]
    }, {
        label: 'Overnatning',
        label_de: 'Übernachtungsmöglichkeiten',
        label_gb: 'Overnight accommodation',
        color: '#aaaaaa',
        geotype: EGeoType.Point,
        themes: [
            {category: 3012, source: ESource.geofa, type: EGeoType.Point},
            {category: 3031, source: ESource.geofa, type: EGeoType.Point},
            {category: 3071, source: ESource.geofa, type: EGeoType.Point},
        ]
    },
    {
        label: 'Faciliteter',
        label_de: 'Einrichtungen',
        label_gb: 'Facilities',
        color: '#b20d0d',
        geotype: EGeoType.Point,
        themes: [
            { category: 1022, source: ESource.geofa, type: EGeoType.Point },
            { category: 3091, source: ESource.geofa, type: EGeoType.Point },
            { category: 1201, source: ESource.geofa, type: EGeoType.Point },
            { category: 1122, source: ESource.geofa, type: EGeoType.Point },
            { category: 1012, source: ESource.geofa, type: EGeoType.Point },
            { category: 1222, source: ESource.geofa, type: EGeoType.Point },
            { category: 1351, source: ESource.geofa, type: EGeoType.Point },
            { category: 1291, source: ESource.geofa, type: EGeoType.Point },
            { category: 5607, source: ESource.geofa, type: EGeoType.Point }
        ]
    }
];
