export interface LocationDetails {
    center: [number, number]; // Tuple for the center coordinates
    zoom: number;             // Zoom level
    filter?: {}
}

export interface Locations {
    [key: string]: LocationDetails; // Dynamic keys for each location
}

export const LOCATIONS:Locations = {

    agerskov: {
        center: [
            1015228.0470402921,
            7385901.748822221
        ],
        zoom: 12.96826271381699,
        filter: {

        }
    },
    arrild: {
        center: [
            994450.9944385405,
            7394234.344509038
        ],
        zoom: 13.566652425130208

    },
    ballum: {
        center: [
            963935.7226651872,
            7376547.276711265
        ],
        zoom: 13.00
    },
    bedsted: {
        center:[
            1013567.9737762664,
            7373474.783781357
        ],
        zoom: 15.086669710343244
    },
    branderup: {
        center: [
            1009668.9877707782,
            7385402.890901289
        ],
        zoom: 14.086845484309727
    },
    bredebro: {
        center: [
            982652.4790488671,
            7372674.475998126
        ],
        zoom: 13.586206863721204

    },
    hjerpsted: {
        center: [
            963230.0397226867,
            7366168.78945367
        ],
        zoom: 14.858237499660907

    },
    hojer: {
        center: [
            967513.2558012753,
            7353637.995110357
        ],
        zoom: 14.459770041571709
    },
    jejsing: {
        center: [
            997617.5764560924,
            7349566.717481386
        ],
        zoom: 14.124079503648801

    },
    logumkloster: {
        center: [
            996465.0769542233,
            7373098.9138948
        ],
        zoom: 13.516161211497232
    },
    tranen: {
        center: [
            979962.6633314885,
            7350622.7301116595
        ],
        zoom: 14.535318285789744

    },
    "norre-logum-ellum": {
        center: [
            992592.611649931,
            7376567.002458986
        ],
        zoom: 12.955497112439751

    },
    rangstrup: {
        center: [
            1025124.6552413199,
            7390090.264759747
        ],
        zoom: 13.518715478744774

    },
    toftlund: {
        center:[
            1008308.7638392263,
            7399091.521686355
        ],
        zoom: 13.937441954215368

    },
    visby: {
        center: [
            977113.4267086572,
            7363528.076059323
        ],
        zoom: 13.236292515463306

    },
    hojst: {
        center: [
            1005913.6135311717,
            7361917.898048908
        ],
        zoom: 14.633482761515522
    },
    "geopark-mtb-svendborg-roed": {
        center: [
            1183028.2295048856,
            7370347.624244897
        ],
        zoom: 13.147285968777313
    }

}
