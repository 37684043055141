import * as React from "react";
import {useContext, useEffect, useRef, useState} from "react";
import * as ol from "ol";
import {OSM} from "ol/source";
import {ScaleLine} from "ol/control";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {defaults} from "ol/interaction";

import {GeoFaIconService} from "../../../services/GeoFaIconService";
import {AppConfig} from "../../../utils/AppConfig";

import styles from "./LocationMap.module.css";
import MapContext from "../../../contexts/MapContext";
import LocationMapContext from "./LocationMapContext";


import {LocationDetails} from "../../../utils/locations";

interface DetailMapProps {
    locationName: string
    location: LocationDetails
}

/**
 * DetailPage Map showing the selected Facility (point or line, later polygon) and everything around it.
 * The Map is static and cannot be zoom/panned.
 * Parametrize: TODO: yes please
 *
 * @param mapId
 * @constructor
 */
const LocationMap: React.FC<DetailMapProps> = ({locationName, location}) => {

    const {getState} = useContext(MapContext);

    const [loaded, setLoaded] = useState<boolean>(false);

    const mapRef = useRef<HTMLDivElement>(null);
    const [map] = useState(null);

    const [view, setView] = useState<ol.View>(new ol.View({
        projection: 'EPSG:900913',
        zoom: location.zoom,
        center: location.center,
        enableRotation: AppConfig.map.enableRotation
    }));

    /*
    Lazy loading of the map when the slide is active (swiped into the viewport)
     */
    useEffect(() => {

        loadMap();

    }, []);

    function loadMap() {

        console.log('loading map');

        let osm = new OSM();

        const scaleLineControl = new ScaleLine({
            units: 'metric'
        });

        let o = {
            view: view,
            layers: [
                new TileLayer({
                    source: osm
                })
                // detailPointLayer,
            ],
            controls: [],
            overlays: [],
            interactions: defaults({
                altShiftDragRotate: false,
                pinchRotate: false,
                mouseWheelZoom: false,
                doubleClickZoom: false,
                shiftDragZoom: false,
                dragPan: false
            })
        };

        let mapObject = new ol.Map(o);

        mapObject.setTarget(locationName);

        setTimeout(() => {
            mapObject.updateSize();
            setLoaded(true);
        }, 100);
    }

    return (
        <>
            <div className={styles.mapContainerOne}>
                <LocationMapContext.Provider value={map!}>
                    <div ref={mapRef}
                         className={styles.mapContainer}
                         id={locationName}>
                    </div>
                </LocationMapContext.Provider>
            </div>
        </>
    )
}

export default LocationMap;
